<template>
  <div id="page-datev-export">
    <v-container grid-list-xl fluid>
      <v-card>
        <v-card-title>Datev export</v-card-title>
        <v-card-text>
          <v-form ref="exportForm" lazy-validation v-model="valid">
            <v-row>
              <v-col cols="12" sm="6" md="2">
                <v-select
                  v-model="selectedProject"
                  :items="compProjects"
                  :label="$vuetify.lang.t('$vuetify.different.project') + '*'"
                  :rules="[rules.required]"
                  required
                  item-value="id"
                  item-text="name"
                  prepend-icon="mdi-briefcase"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="2">
                <v-menu
                  v-model="fromPiker"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="250px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="from"
                      :label="$vuetify.lang.t('$vuetify.dates.from') + '*'"
                      prepend-icon="event"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :rules="[rules.required]"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    first-day-of-week="1"
                    v-model="from"
                    @input="fromPiker = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6" md="2">
                <v-menu
                  v-model="toPiker"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="250px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="to"
                      :label="$vuetify.lang.t('$vuetify.dates.to') + '*'"
                      prepend-icon="event"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :rules="[rules.required]"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    first-day-of-week="1"
                    v-model="to"
                    @input="toPiker = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6" md="2">
                <v-select
                  v-model="source"
                  :items="sources"
                  item-text="value"
                  item-value="value"
                  :label="$vuetify.lang.t('$vuetify.different.source') + '*'"
                  required
                  :rules="[rules.required]"
                  prepend-icon="list"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="2">
                <v-select
                  v-model="type"
                  :items="types"
                  item-text="value"
                  item-value="value"
                  :label="$vuetify.lang.t('$vuetify.different.type') + '*'"
                  required
                  :rules="[rules.required]"
                  prepend-icon="list"
                ></v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            small
            :loading="downloadLoader"
            @click="getExport"
            :disabled="!valid"
          >
            <v-icon left small>cloud_download</v-icon>
            {{ $vuetify.lang.t('$vuetify.buttons.download') }} datev
          </v-btn>
          <v-btn
            color="primary"
            small
            :loading="downloadEbayLoader"
            @click="getEbayDates"
            :disabled="!valid"
          >
            <v-icon left small>cloud_download</v-icon>
            {{ $vuetify.lang.t('$vuetify.buttons.download') }} ebay dates
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-row class="mt-4">
        <v-col>
          <v-alert outlined v-model="alert" dismissible type="error">
            {{ $vuetify.lang.t('$vuetify.errors.error') }}. <br/>
            <v-btn
              elevation="2"
              small
              class="mt-2"
              @click="download('api/datev/logs', 'logs.xlsx')"
            >{{ $vuetify.lang.t('$vuetify.buttons.download') }}
              {{ $vuetify.lang.t('$vuetify.different.log') }}
            </v-btn>
          </v-alert>
        </v-col>
      </v-row>
    </v-container>
    <AppLoader :dialog.sync="loading"></AppLoader>
    <v-snackbar
      :timeout="3000"
      bottom
      right
      :color="snackbar.color"
      v-model="snackbar.show"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text @click.native="snackbar.show = false" icon>
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import AppLoader from '@/components/AppLoader'

export default {
  components: {
    AppLoader
  },
  data() {
    return {
      valid: true,
      fromPiker: false,
      toPiker: false,
      downloadLoader: false,
      downloadEbayLoader: false,
      loading: false,
      from: null,
      to: null,
      selectedProject: null,
      type: 'csv',
      source: 'shopware',
      types: [{value: 'xlsx'}, {value: 'csv'}],
      sources: [{value: 'shopware'}, {value: 'ebay'}],
      alert: false,
      rules: {
        required: (v) =>
          !!v || this.$vuetify.lang.t('$vuetify.validation.required'),
        max191: (v) =>
          (v && v.length <= 191) ||
          this.$vuetify.lang.t('$vuetify.validation.max191')
      },
      snackbar: {
        show: false,
        text: '',
        color: ''
      },
    }
  },
  metaInfo() {
    return {
      title: this.$vuetify.lang.t('$vuetify.different.export')
    }
  },
  mounted() {
    this.from = this.firstDayInPreviousMonth()
    this.to = this.lastDayInPreviousMonth()
  },
  computed: {
    compAuthHeader() {
      return {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('access_token')
        }
      }
    },
    compProjects() {
      return this.$store.getters.getProjects
    }
  },
  methods: {
    getExport() {
      if (this.$refs.exportForm.validate()) {
        let data = {
          date_start: this.from,
          date_end: this.to,
          project_id: this.selectedProject,
          type: this.type,
          source: this.source
        }
        this.loading = this.downloadLoader = true
        this.$axios
          .post(
            this.$store.getters.getApiUrl + 'api/datev/export',
            data,
            this.compAuthHeader
          )
          .then((response) => {
            this.loading = this.downloadLoader = false
            this.alert = !!response.data.logs
            if (response.status === 200)
              this.download('api/datev/download', response.data.file_name)
            else {
              this.snackbar.show = true
              this.snackbar.color = 'red'
              this.snackbar.text = this.$vuetify.lang.t('$vuetify.errors.error')
            }
          })
          .catch((error) => {
            this.loading = this.downloadLoader = false
            console.log(error)
          })
      }
    },
    getEbayDates() {
      if (this.$refs.exportForm.validate()) {
        let data = {
          date_start: this.from,
          date_end: this.to,
          project_id: this.$store.getters.getCurrentProject.id
        }
        this.loading = this.downloadEbayLoader = true
        let headers = {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('access_token')
          },
          responseType: 'blob'
        }
        this.$axios
          .post(
            this.$store.getters.getApiUrl + 'api/ebay/download-dates',
            data,
            headers
          )
          .then((response) => {
            this.loading = this.downloadEbayLoader = false

            const downloadUrl = URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = downloadUrl
            link.setAttribute(
              'download',
              'ebay_' + this.from + '_' + this.to + '_dates.csv'
            )
            document.body.appendChild(link)
            link.click()
            link.remove()
          })
          .catch((error) => {
            this.loading = this.downloadEbayLoader = false
            console.log(error)
          })
      }
    },
    firstDayInPreviousMonth() {
      let now = new Date()
      return new Date(now.getFullYear(), now.getMonth() - 1, 2)
        .toJSON()
        .slice(0, 10)
    },
    lastDayInPreviousMonth() {
      let now = new Date()
      return new Date(now.getFullYear(), now.getMonth()).toJSON().slice(0, 10)
    },
    download(link, name) {
      let headers = {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        },
        responseType: 'blob'
      }

      this.$axios
        .post(this.$store.getters.getApiUrl + link, {}, headers)
        .then((response) => {
          const downloadUrl = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = downloadUrl
          link.setAttribute('download', name)
          document.body.appendChild(link)
          link.click()
          link.remove()
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }
}
</script>
